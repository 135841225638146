import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Signup from './pages/Signup';
import DashboardNew from './pages/DashboardNew';
import Dashboard from './pages/Dashboard';
import './App.css';
import SuccessPage from './components/SuccessPage';
import ProtectedRoute from './pages/ProtectedRoute.js';
import BuyData from './pages/BuyData.jsx';
import BuyAirtime from './pages/BuyAirtime.jsx';
import BuyElectricity from './pages/BuyElectricity.jsx';
import BuyCableTv from './pages/BuyCableTv.jsx';
import Kyc from './pages/Kyc.jsx';
import TransactionHistory from './pages/TransactionHistory.jsx';
import UpdateProfile from './pages/UpdateProfile.jsx';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/dashboard-new" element={<DashboardNew/>} />
          <Route path="/successpage" element={<SuccessPage/>} />
          <Route path="/" element={<Login/>} />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/buy-data"
            element={
              <ProtectedRoute>
                <BuyData />
              </ProtectedRoute>
            }
          />
          <Route
            path="/buy-airtime"
            element={
              <ProtectedRoute>
                <BuyAirtime />
              </ProtectedRoute>
            }
          />
          <Route
            path="/buy-electricity"
            element={
              <ProtectedRoute>
                <BuyElectricity />
              </ProtectedRoute>
            }
          />
          <Route
            path="/cable-tv"
            element={
              <ProtectedRoute>
                <BuyCableTv />
              </ProtectedRoute>
            }
          />
          <Route
            path="/kyc"
            element={
              <ProtectedRoute>
                <Kyc />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute>
                <UpdateProfile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/transactions"
            element={
              <ProtectedRoute>
                <TransactionHistory />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
