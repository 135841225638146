import React, { useState, useEffect } from 'react';
import { FaWallet, FaPhone, FaReceipt, FaEllipsisH, FaBars, FaTimes, FaTachometerAlt, FaUser, FaHistory, FaMobileAlt, FaWifi, FaBolt, FaTv, FaUserCircle, FaSignOutAlt, FaChevronDown } from 'react-icons/fa';
import logo from '../assets/img/rumbundata.png';
import userAvatar from '../assets/img/user-avatar.png'; // Add path to user avatar image
import '../assets/Main.css';

const DashboardNew = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it once to set initial state based on screen size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const actions = [
    { name: 'Buy Data', icon: <FaWifi /> },
    { name: 'Buy Airtime', icon: <FaPhone /> },
    { name: 'Buy Electricity', icon: <FaBolt /> },
    { name: 'Pay Cable TV', icon: <FaReceipt /> }
  ];

  return (
    <div className={`dashboard-container ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      {/****sidebar****/}
      <button className="toggle-button" onClick={toggleSidebar}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>
      <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
        <div className="logo">
          <h2><img src={logo} alt="Logo" className='dashboard-logo' /></h2>
        </div>
        <nav>
          <ul>
            <li><FaTachometerAlt /> <a href="#dashboard">Dashboard</a></li>
            <li><FaUser /> <a href="#account">Account</a></li>
            <li><FaHistory /> <a href="#transaction-history">Transaction History</a></li>
            <li><FaMobileAlt /> <a href="#buy-airtime">Buy Airtime</a></li>
            <li><FaWifi /> <a href="#buy-data">Buy Data</a></li>
            <li><FaBolt /> <a href="#buy-electricity">Buy Electricity</a></li>
            <li><FaTv /> <a href="#cable-tv">Cable/TV Subscription</a></li>
          </ul>
        </nav>
      </div>
      {/***end sidebar*****/}
      <div className="main-content">
        {/*****header****/}
        <header className="header">
          <div className="header-content">
            <div className="user-info">
              <div className="profile" onClick={toggleDropdown}>
                <img src={userAvatar} alt="User Avatar" className="user-avatar" />
                <span className="user-name">Makinde N.</span>
                <FaChevronDown className={`dropdown-arrow ${dropdownOpen ? 'rotate' : ''}`} />
                <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
                  <ul>
                    <li><FaUserCircle /> <a href="#profile">Profile</a></li>
                    <li><FaSignOutAlt /> <a href="#logout">Logout</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/**end header****/}
        {/*****MyContent**/}
        <div className="dashboard">
          <h2>Dashboard</h2>
          <div className="dashboard-cards">
            <div className="card wallet-balance">
              <h3>Wallet Balance</h3>
              <p className="balance">₦ 250,0000</p>
              <div className="hide-balance">
                <span>Hide Balance</span>
                <div className="toggle-switch">
                  <div className="toggle"></div>
                </div>
              </div>
            </div>
            <div className="card bank-account">
              <h3>MY Bank Account</h3>
              <p className="bank-name">WEMA BANK</p>
              <p className="account-number">200345687</p>
            </div>
          </div>

          <div className="actions-container">
            <center>
              {actions.map((action, index) => (
                <button key={index} className="action-item">
                  {action.icon}
                  <span className="action-text">{action.name}</span>
                </button>
              ))}
            </center>
          </div>

          <div className="transaction-history">
            <h3>Transaction history</h3>
            <div className="transaction-search">
              <input type="text" placeholder="Search..." />
              <button>🔍</button>
            </div>
            <table>
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Description</th>
                  <th>Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {/* Transaction rows will go here */}
              </tbody>
            </table>
          </div>
        </div>
        {/*****MyContent**/}
        {/*****Footer**/}
        <footer className="footer">
          <p>powered by AITS HUB • support line: 0803779040, 08163069764</p>
        </footer>
        {/*****Footer**/}
      </div>
    </div>
  );
};

export default DashboardNew;
