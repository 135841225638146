import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import Sidebar from '../components/SideBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../assets/Main.css';
import '../assets/Kyc.css';
import Swal from 'sweetalert2';
import { getAuthUser } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../apiConfig'; // Import the base URL

const Kyc = () => {
  const [isOpen, setIsOpen] = useState(true);
  const user = getAuthUser();
  // Form state
  const [formData, setFormData] = useState({
    bvn: '',
    nin: ''
});

const [errors, setErrors] = useState({});
const [loading, setLoading] = useState(false); // Add loading state
const navigate = useNavigate(); // Initialize useNavigate

// Handle input changes
const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });

  // Validate input on change
  if (errors[name]) {
    validateField(name, value);
  }
};

// Validate individual fields
const validateField = (name, value) => {
  let fieldErrors = { ...errors };

  switch (name) {
    case 'bvn':
      fieldErrors.bvn = value ? '' : 'BVN is required';
      break;
    default:
      break;
  }

  setErrors(fieldErrors);
};

// Validate entire form
const validateForm = () => {
  let formErrors = {};

  if (!formData.bvn) formErrors.bvn = 'BVN is required';
  
  setErrors(formErrors);
  return Object.keys(formErrors).length === 0;
};

// Handle form submission
const handleSubmit = async () => {
  if (validateForm()) {
    setLoading(true); // Start loading
    try {
      const response = await fetch(`${API_BASE_URL}user/verify-kyc`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.data.token}`,
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json(); // Parse the response
      //console.log(data);

      if (response.ok) {
          // Update kyc_status in localStorage
          const updatedUser = {
              ...user,
              data: {
                ...user.data,
                user: {
                    ...user.data.user,
                    kyc_status: 1, // Set kyc_status to 1
                },
              },
          };
          // Save the updated user object back to localStorage
          localStorage.setItem('authUser', JSON.stringify(updatedUser));

          Swal.fire({
              icon: 'success',
              title: 'KYC Verification',
              text: 'KYC verification Successfully Submitted',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
          });

          navigate(`/dashboard`); // Redirect to dashboard

      } else {

          let errorMessage;
          if (data.data && typeof data.data === 'object') {
              errorMessage = Object.keys(data.data)
                .map((key) => data.data[key].join(' '))
                .join(' ');
          } else if (typeof data.data === 'string') {
          errorMessage = data.data; // Use the string directly
          } else {
          errorMessage = 'KYC failed. Please try again.';
          }

          Swal.fire({
              icon: 'error',
              title: 'KYC Verification Failed',
              text: errorMessage,
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Try Again'
          });
      }
    } catch (error) {
      // Display SweetAlert for network or server errors
      Swal.fire({
          icon: 'error',
          title: 'An Error Occurred',
          text: 'Please try again later. ',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Try Again'
      });
    } finally {
      setLoading(false); // Stop loading
    }
  }
};
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it once to set initial state based on screen size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`dashboard-container ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        
        {/****sidebar****/}
        <button className="toggle-button" onClick={toggleSidebar}>
            {isOpen ? <FaTimes /> : <FaBars />}
        </button>
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        {/***end sidebar*****/}

        <div className="main-content">
            {/*****header****/}
            <Header />
            {/**end header****/}

            {/*****MyContent**/}
            <div className="dashboard">
                <h2>Complete KYC to generate your wallet account </h2>
                <div className="kycForm">
                    <div>
                        <div className="form-group">
                            <label htmlFor="bvn">BVN (required)</label>
                            <input 
                                type="text" 
                                name="bvn" 
                                id="bvn" 
                                placeholder="Enter your BVN" required 
                                value={formData.bvn}
                                onChange={handleChange}
                                onBlur={() => validateField('bvn', formData.bvn)}
                            />
                            {errors.bvn && <span className="error">{errors.bvn}</span>}
                        </div>
                        
                        <div className="form-group">
                            <label htmlFor="nin">NIN (optional)</label>
                            <input 
                                type="text" 
                                name="nin" 
                                id="nin" 
                                placeholder="Enter your NIN" required 
                                value={formData.nin}
                                onChange={handleChange}
                                onBlur={() => validateField('nin', formData.nin)}
                            />
                        </div>
                        
                        <div className="form-group">
                            <button 
                                type="submit" 
                                className={`submit-button ${loading ? 'disabled' : ''}`}
                                onClick={handleSubmit}
                            >
                               {loading ? (
                                    <>
                                    <div className="spinner"></div>
                                    <span>Submitting KYC...</span>
                                    </>
                                ) : (
                                    'Submit Kyc'
                                )} 
                                
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/*****MyContent**/}

            {/*****Footer**/}
            <Footer />
            {/*****Footer**/}
      </div>
    </div>
  );
};

export default Kyc;
