import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import Sidebar from '../components/SideBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../assets/Main.css';
import '../assets/Kyc.css';
import '../assets/UpdateProfileForm.css';
import Swal from 'sweetalert2';
import { getAuthUser } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../apiConfig'; // Import the base URL

const UpdateProfile = () => {
  const [isOpen, setIsOpen] = useState(true);
  const user = getAuthUser();
    const navigate = useNavigate();

    // Form state
    const [formData, setFormData] = useState({
        passport: null, // File input should start as null
        phone: user?.data?.user?.phone || '',
        dob: user?.data?.user?.dob || '',
        gender: user?.data?.user?.gender || '',
        address: user?.data?.user?.address || '',
        username: user?.data?.user?.username || ''
    });

    // State for image preview
    const [passportPreview, setPassportPreview] = useState(null);

    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);

    const handleChange = (e) => {
        const { name, value, type, files } = e.target;

        // Handle file input separately
        if (type === 'file') {
            const file = files[0];
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: file,
            }));

            // Generate and set image preview URL
            if (file) {
                const previewURL = URL.createObjectURL(file);
                setPassportPreview(previewURL);
            } else {
                setPassportPreview(null);
            }

            if (errors[name]) {
                validateField(name, file);
            }
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));

            if (errors[name]) {
                validateField(name, value);
            }
        }
    };

    const validateField = (name, value) => {
        let fieldErrors = { ...errors };

        switch (name) {
            case 'passport':
                fieldErrors.passport = value && value.size > 0 ? '' : 'Passport is required';
                break;
            case 'phone':
                fieldErrors.phone = value ? '' : 'Phone number is required';
                break;
            case 'dob':
                fieldErrors.dob = value ? '' : 'Date of Birth is required';
                break;
            case 'gender':
                fieldErrors.gender = value ? '' : 'Gender is required';
                break;
            case 'address':
                fieldErrors.address = value ? '' : 'Address is required';
                break;
            case 'username':
                fieldErrors.username = value ? '' : 'Username is required';
                break;
            default:
                break;
        }

        setErrors(fieldErrors);
    };

    const validateForm = () => {
        let formErrors = {};

        if (!formData.phone) formErrors.phone = 'Phone No. is required';
        if (!formData.dob) formErrors.dob = 'Date of Birth is required';
        if (!formData.gender) formErrors.gender = 'Gender is required';
        if (!formData.address) formErrors.address = 'Address is required';
        if (!formData.username) formErrors.username = 'Username is required';
        
        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (validateForm()) {
            setLoading(true);
            try {
                const formDataToSend = new FormData();
                if (formData.passport) {
                    formDataToSend.append('passport', formData.passport);
                }
                formDataToSend.append('username', formData.username);
                formDataToSend.append('phone', formData.phone);
                formDataToSend.append('dob', formData.dob);
                formDataToSend.append('gender', formData.gender);
                formDataToSend.append('address', formData.address); 

                const response = await fetch(`${API_BASE_URL}user/update-profile`, {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${user.data.token}`,
                    },
                    body: formDataToSend,
                });

                const data = await response.json();

                if (response.ok) {
                    const updatedUser = {
                        ...user,
                        data: {
                            ...user.data,
                            user: {
                                ...user.data.user,
                                passport: data.data.passport,
                                gender: data.data.gender,
                                dob: data.data.dob,
                                phone: data.data.phone, 
                                address: data.data.address,
                                username: data.data.username
                            },
                        },
                    };
                    localStorage.setItem('authUser', JSON.stringify(updatedUser));
                    navigate(`/dashboard`);
                    Swal.fire({
                        icon: 'success',
                        title: 'Profile Update',
                        text: 'Profile updated successfully',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Ok'
                    });
                } else {
                    let errorMessage;
                    if (data.data && typeof data.data === 'object') {
                        errorMessage = Object.keys(data.data)
                            .map((key) => data.data[key].join(' '))
                            .join(' ');
                    } else if (typeof data.data === 'string') {
                        errorMessage = data.data;
                    } else {
                        errorMessage = 'Update failed. Please try again.';
                    }

                    Swal.fire({
                        icon: 'error',
                        title: 'Update Failed',
                        text: errorMessage,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'Try Again'
                    });
                }
            } catch (error) {
                //console.log(error);
                Swal.fire({
                    icon: 'error',
                    title: 'An Error Occurred',
                    text: 'Please try again later.',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Try Again'
                });
            } finally {
                setLoading(false);
            }
        }
    };
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it once to set initial state based on screen size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`dashboard-container ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        
        {/****sidebar****/}
        <button className="toggle-button" onClick={toggleSidebar}>
            {isOpen ? <FaTimes /> : <FaBars />}
        </button>
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        {/***end sidebar*****/}

        <div className="main-content">
            {/*****header****/}
            <Header />
            {/**end header****/}

            {/*****MyContent**/}
            <div className="dashboard">
                <h2>Update Profile</h2>
                {user.data.user.kyc_status === 1 && (
                    <div className="profileForm kycForm">
                        <div className="left-column">
                            <div className="form-group">
                                
                                {passportPreview && (
                                    <div className="passport-preview">
                                        <img src={passportPreview} className="prev-img" alt="Passport Preview" />
                                    </div>
                                )}
                                <label htmlFor="passport">Upload Passport</label>
                                <input
                                    type="file"
                                    name="passport"
                                    id="passport"
                                    onChange={handleChange}
                                />
                                {errors.passport && <span className="error">{errors.passport}</span>}
                            </div>
                        </div>

                        <div className="right-column">
                            <div className="form-group">
                                <label htmlFor="username">Username</label>
                                <input
                                    type="text"
                                    name="username"
                                    id="username"
                                    placeholder="Update your username."
                                    value={formData.username}
                                    onChange={handleChange}
                                    onBlur={() => validateField('username', formData.username)}
                                />
                                {errors.username && <span className="error">{errors.username}</span>}
                            </div>
                            <div className="form-group">
                                <label htmlFor="phone">Phone No.</label>
                                <input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    placeholder="Update your phone no."
                                    value={formData.phone}
                                    onChange={handleChange}
                                    onBlur={() => validateField('phone', formData.phone)}
                                />
                                {errors.phone && <span className="error">{errors.phone}</span>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="dob">Date of Birth</label>
                                <input
                                    type="date"
                                    name="dob"
                                    id="dob"
                                    placeholder="Update your date of birth"
                                    value={formData.dob}
                                    onChange={handleChange}
                                    onBlur={() => validateField('dob', formData.dob)}
                                />
                                {errors.dob && <span className="error">{errors.dob}</span>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="gender">Gender</label>
                                <select
                                    name="gender"
                                    id="gender"
                                    required
                                    value={formData.gender}
                                    onChange={handleChange}
                                    onBlur={() => validateField('gender', formData.gender)}
                                >
                                    <option value="">Select your gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                </select>
                                {errors.gender && <span className="error">{errors.gender}</span>}
                            </div>

                            <div className="form-group">
                                <label htmlFor="address">Contact Address</label>
                                <textarea
                                    name="address"
                                    id="address"
                                    placeholder="Enter your contact address"
                                    rows="4"
                                    value={formData.address}
                                    onChange={handleChange}
                                    onBlur={() => validateField('address', formData.address)}
                                ></textarea>
                                {errors.address && <span className="error">{errors.address}</span>}
                            </div>

                            <div className="form-group">
                                <button
                                    type="submit"
                                    className={`submit-button ${loading ? 'disabled' : ''}`}
                                    onClick={handleSubmit}
                                >
                                    {loading ? (
                                        <>
                                            <div className="spinner"></div>
                                            <span>Submitting...</span>
                                        </>
                                    ) : (
                                        'Update Profile'
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                )}
            </div>
            {/*****MyContent**/}

            {/*****Footer**/}
            <Footer />
            {/*****Footer**/}
      </div>
    </div>
  );
};

export default UpdateProfile;
