import React, {useEffect} from 'react';
import { useLocation,Link,useNavigate } from 'react-router-dom';
import '../assets/Login.css'; // Ensure this CSS file is created and referenced correctly
import logo from '../assets/img/rumbundata.png';

import { getAuthUser } from '../utils/auth';

const SuccessPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (getAuthUser()) {
        navigate('/dashboard'); // Redirect to dashboard if the user is logged in
    }
  }, [navigate]);

  return (
    <>
      <div className="login-container">
        <div className="login-content">
          <div className="login-image"></div>
          <div className="login-form">
            <center><img src={logo} alt="Rumbun Data Logo" className="login-logo" /></center>
            <center>
                <div className="text">Sign Up Successful</div>
                <div className="underline"></div>
            </center>

            <div className="succes-page">
                <h3>Hi <strong>{email}</strong>! </h3>
                <p>You have successfully signed up for Rumbun Data App.</p>
            </div>
        
            <div className="success-back">
                <strong>click&nbsp;<Link to="/login" className="success-link">here</Link>&nbsp;to log in.</strong>
            </div>
            
          </div>
        </div>
      </div>
      <footer>
        <p className='footer-item'>
          Powered by AITS HUB - Support Line: 09030848790, 08136908764
        </p>
      </footer>
    </>
  );

};

export default SuccessPage;
