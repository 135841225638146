// Dashboard.jsx
import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import Sidebar from '../components/SideBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../assets/Main.css';
import '../assets/Kyc.css';
import { getAuthUser } from '../utils/auth';

const BuyData = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const user = getAuthUser();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it once to set initial state based on screen size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleFormSubmit = async () => {

  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`dashboard-container ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        
        {/****sidebar****/}
        <button className="toggle-button" onClick={toggleSidebar}>
            {isOpen ? <FaTimes /> : <FaBars />}
        </button>
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        {/***end sidebar*****/}

        <div className="main-content">
            {/*****header****/}
            <Header />
            {/**end header****/}

            {/*****MyContent**/}
            <div className="dashboard">
                <h2>Buy Data</h2>
                {user.data.user.kyc_status === 1 && (
                <div className="page-content">
                    <div className="kycForm">
                        <div>
                            <div className="form-group">
                                {/* <label htmlFor="bvn">Network</label> */}
                                <select  
                                    name="network" 
                                    id="network"
                                >
                                    <option value="">Select Network Type</option>
                                    <option value="MTN">MTN</option>
                                    <option value="Airtel">Airtel</option>
                                    <option value="GLO">GLO</option>
                                    <option value="9Mobile">9Mobile</option>
                                </select>
                                
                            </div>
                            
                            <div className="form-group">
                                {/* <label htmlFor="nin">Data Type</label> */}
                                <select  
                                    name="datatype" 
                                    id="datatype"
                                >
                                    <option value="">Select Data Type</option>
                                </select>
                            </div>
                            <div className="form-group">
                                {/* <label htmlFor="nin">Reciepient Phone Number</label> */}
                                <input 
                                    type="text" 
                                    name="datatype" 
                                    id="datatype"
                                    placeholder="Reciepient Phone Number"
                                />
                            </div>
                            
                            <div className="form-group">
                                <button 
                                    type="submit" 
                                    className={`submit-button ${loading ? 'disabled' : ''}`}
                                    onClick={handleFormSubmit}
                                >
                                {loading ? (
                                        <>
                                        <div className="spinner"></div>
                                        <span>Buying Data...</span>
                                        </>
                                    ) : (
                                        'Buy Data'
                                    )} 
                                    
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                )}
            </div>
            {/*****MyContent**/}

            {/*****Footer**/}
            <Footer />
            {/*****Footer**/}
      </div>
    </div>
  );
};

export default BuyData;
