import React, { useState,useEffect } from 'react';
import { FaUser, FaPhone, FaEnvelope, FaLock, FaEye, FaEyeSlash, FaCode } from 'react-icons/fa';
import '../assets/Login.css'; // Ensure this CSS file is created and referenced correctly
import logo from '../assets/img/rumbundata.png';
import { API_BASE_URL } from '../apiConfig'; // Import the base URL
import Swal from 'sweetalert2';
import { getAuthUser } from '../utils/auth';
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate(); // Initialize useNavigate

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  useEffect(() => {
    if (getAuthUser()) {
        navigate('/dashboard'); // Redirect to dashboard if the user is logged in
    }
  }, [navigate]);
  
  // Form state
  const [formData, setFormData] = useState({
    fullname: '',
    phone: '',
    email: '',
    password: '',
    confirmPassword: '',
    referralCode: '',
  });


  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate input on change
    if (errors[name]) {
      validateField(name, value);
    }
  };

  // Validate individual fields
  const validateField = (name, value) => {
    let fieldErrors = { ...errors };

    switch (name) {
      case 'fullname':
        fieldErrors.fullname = value ? '' : 'Full Name is required';
        break;
      case 'phone':
        fieldErrors.phone = value ? '' : 'Phone number is required';
        break;
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        fieldErrors.email = value
          ? emailRegex.test(value)
            ? ''
            : 'Invalid email address'
          : 'Email is required';
        break;
      case 'password':
        fieldErrors.password = value ? '' : 'Password is required';
        break;
      case 'confirmPassword':
        fieldErrors.confirmPassword = value ? '' : 'Confirm Password is required';
        if (value !== formData.password) {
          fieldErrors.confirmPassword = 'Passwords do not match';
        }
        break;
      default:
        break;
    }

    setErrors(fieldErrors);
  };

  // Validate entire form
  const validateForm = () => {
    let formErrors = {};

    if (!formData.fullname) formErrors.fullname = 'Full Name is required';
    if (!formData.phone) formErrors.phone = 'Phone number is required';
    if (!formData.email) {
      formErrors.email = 'Email is required';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        formErrors.email = 'Invalid email address';
      }
    }
    if (!formData.password) formErrors.password = 'Password is required';
    if (!formData.confirmPassword) formErrors.confirmPassword = 'Confirm Password is required';
    if (formData.password !== formData.confirmPassword)
      formErrors.confirmPassword = 'Passwords do not match';

    //if (!agreedToTerms) formErrors.terms = 'You must agree to the terms and conditions';

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true); // Start loading
      try {
        const response = await fetch(`${API_BASE_URL}user/signup`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        const data = await response.json(); // Parse the response

        if (response.ok) {
          navigate(`/successpage?email=${formData.email}`); // Redirect to SuccessPage with email
        } else {

            let errorMessage;
            if (data.data && typeof data.data === 'object') {
                errorMessage = Object.keys(data.data)
                  .map((key) => data.data[key].join(' '))
                  .join(' ');
            } else if (typeof data.data === 'string') {
            errorMessage = data.data; // Use the string directly
            } else {
            errorMessage = 'Signup failed. Please try again.';
            }

            Swal.fire({
                icon: 'error',
                title: 'Sign Up Failed',
                text: errorMessage,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Try Again'
            });
        }
      } catch (error) {
        // Display SweetAlert for network or server errors
        Swal.fire({
            icon: 'error',
            title: 'An Error Occurred',
            text: 'Please try again later. ',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
        });
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

  return (
    <>
      <div className="login-container">
        <div className="login-content">
          <div className="signup-image"></div>
          <div className="login-form">
            <center><img src={logo} alt="Rumbun Data Logo" className="login-logo" /></center>
            <h4>Sign Up</h4>
            <div>
              <div className="input-group">
                <FaUser className="input-icon" />
                <input
                    type="text"
                    name="fullname"
                    placeholder="Full Name"
                    value={formData.fullname}
                    onChange={handleChange}
                    onBlur={() => validateField('fullname', formData.fullname)}
                />
                {errors.fullname && <span className="error">{errors.fullname}</span>}
              </div>
              <div className="input-group">
                <FaPhone className="input-icon" />
                <input
                    type="text"
                    name="phone"
                    placeholder="Phone"
                    value={formData.phone}
                    onChange={handleChange}
                    onBlur={() => validateField('phone', formData.phone)}
                />
                {errors.phone && <span className="error">{errors.phone}</span>}
              </div>
              <div className="input-group">
                <FaEnvelope className="input-icon" />
                <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={() => validateField('email', formData.email)}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
              <div className="input-group">
                <FaLock className="input-icon" />
                <input 
                    type={showPassword ? "text" : "password"} 
                    placeholder="Password" 
                    required
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    onBlur={() => validateField('password', formData.password)} 
                />
                <button type="button" className="eye-button" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
                {errors.password && <span className="error">{errors.password}</span>}
              </div>
              <div className="input-group">
                <FaLock className="input-icon" />
                <input 
                    type={showConfirmPassword ? "text" : "password"} 
                    placeholder="Confirm Password" 
                    required
                    name="confirmPassword"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    onBlur={() => validateField('confirmPassword', formData.confirmPassword)}
                />
                <button type="button" className="eye-button" onClick={toggleConfirmPasswordVisibility}>
                  {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
                {errors.confirmPassword && (
                    <span className="error">{errors.confirmPassword}</span>
                )}
              </div>
              <div className="input-group">
                <FaCode className="input-icon" />
                <input
                    type="text"
                    name="referralCode"
                    placeholder="Referral Code (optional)"
                    value={formData.referralCode}
                    onChange={handleChange}
                />
              </div>
              <button 
                    type="submit1" 
                    className={`login-button ${loading ? 'disabled' : ''}`}
                    onClick={handleSubmit}
                    >
                    {loading ? (
                        <>
                        <div className="spinner"></div>
                        <span>Submitting...</span>
                        </>
                    ) : (
                        'Sign Up'
                    )}
              </button>
            </div>
            <center>
              <p>Already have an account? <a href="/login" className="signup-link">Login</a><br/></p>
            </center>
          </div>
        </div>
      </div>
      <footer>
        <p className='footer-item'>
          Powered by AITS HUB - Support Line: 09030848790, 08136908764
        </p>
      </footer>
    </>
  );
};

export default Signup;
