// Sidebar.jsx
import React from 'react';
import { FaTachometerAlt, FaUser, FaHistory, FaMobileAlt, FaWifi, FaBolt, FaTv } from 'react-icons/fa';
import logo from '../assets/img/rumbundata.png';
import '../assets/Main.css';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  return (
    <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
      <div className="logo">
        <h2><img src={logo} alt="Logo" className='dashboard-logo' /></h2>
      </div>
      <nav>
        <ul>
          <li><FaTachometerAlt /> <a href="/dashboard">Dashboard</a></li>
          <li><FaUser /> <a href="/profile">Update Profile</a></li>
          <li><FaHistory /> <a href="/transactions">Transaction History</a></li>
          <li><FaMobileAlt /> <a href="/buy-airtime">Buy Airtime</a></li>
          <li><FaWifi /> <a href="/buy-data">Buy Data</a></li>
          <li><FaBolt /> <a href="/buy-electricity">Buy Electricity</a></li>
          <li><FaTv /> <a href="/cable-tv">Cable/TV Subscription</a></li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
