// Footer.jsx
import React from 'react';
import '../assets/Main.css';

const Footer = () => {
  return (
    <footer className="footer">
      <p>powered by AITS HUB • Support line: 09030848790, 08163069764</p>
    </footer>
  );
};

export default Footer;
