import React, { useEffect, useState } from 'react';
import { FaTv, FaWifi, FaPhone, FaBolt, FaCopy } from 'react-icons/fa';
import '../assets/Main.css';
import '../assets/KycAlert.css';
import { getAuthUser } from '../utils/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { API_BASE_URL } from '../apiConfig'; // Import the base URL
import { format } from 'date-fns';

const MyContent = () => {
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const [authUser, setAuthUser] = useState(getAuthUser()); // Set user immediately
  const [accountBalance, setAccountBalance] = useState(0);
  const [accounts, setAccounts] = useState([]);
  const [walletTransactions, setWalletTransactions] = useState([]); // Corrected variable name
  const [isBalanceVisible, setIsBalanceVisible] = useState(false);

  const toggleBalanceVisibility = () => {
    setIsBalanceVisible(!isBalanceVisible); // Toggle balance visibility
  };

  // Fetch wallet accounts
  useEffect(() => {
    const fetchAccounts = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}wallet/my-account-nos`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authUser.data.token}`, // Use the correct token
                },
            });
            if (response.ok) {
                const data = await response.json();
                setAccounts(data.data || []); // Assuming the response contains an 'accounts' array
                //console.log(data.data);
            } else {
                console.error('Failed to fetch accounts');
            }
        } catch (error) {
            console.error('Error fetching accounts:', error);
        }
    };

    fetchAccounts();
  }, [authUser.data.token]);

  // Fetch wallet transactions
  useEffect(() => {
    const fetchWalletTransactions = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}wallet/my-transaction-history`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authUser.data.token}`, // Use the correct token
          },
        });
        if (response.ok) {
          const data = await response.json();
          setWalletTransactions(data.data || {}); // Since data.data is an object
        } else {
          console.error('Failed to fetch transactions');
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };
  
    fetchWalletTransactions();
  }, [authUser.data.token]);
  
  

  // Fetch wallet balance
  useEffect(() => {
    const fetchWalletBalance = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}wallet/my-wallet-balance`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authUser.data.token}`, // Use token from authUser
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          //console.log(data.data);
          const balance = parseFloat(data.data); // Ensure balance is a number
          setAccountBalance(!isNaN(balance) ? balance : 0); // Fallback to 0 if balance is not a valid number
        } else {
          console.error('Failed to fetch wallet balance');
        }
      } catch (error) {
        console.error('Error fetching wallet balance:', error);
      }
    };
    fetchWalletBalance();
  }, [authUser.data.token]);

  const actions = [
    { name: 'Buy Data', icon: <FaWifi />, url: '/buy-data' },
    { name: 'Buy Airtime', icon: <FaPhone />, url: '/buy-airtime' },
    { name: 'Buy Electricity', icon: <FaBolt />, url: '/buy-electricity' },
    { name: 'CableTv', icon: <FaTv />, url: '/cable-tv' },
  ];

  const handleKycRedirect = () => {
    navigate('/kyc');
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
        alert('Copied to clipboard!');
    }).catch(err => {
        alert('Failed to copy: ' + err);
    });
  };

  return (
    <div className="dashboard">
      <h2>Dashboard</h2>
      
      {authUser && authUser.data.user.kyc_status === 1 ? (
        <>
          <div className="dashboard-cards">
            <div className="card wallet-balance">
              <h3 className="pg">Wallet Balance</h3>
              <p className="balance">
                {isBalanceVisible ? `₦ ${accountBalance.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : '*****'}
              </p>
              <div className="hide-balance" onClick={toggleBalanceVisibility}>
                <span>{isBalanceVisible ? 'hide balance' : 'show balance'}</span>
                <div className="toggle-switch">
                  <div className={`toggle ${isBalanceVisible ? 'visible' : 'hidden'}`}></div>
                </div>
              </div>
            </div>
            <div className="card bank-account">
              <h3>Wallet Accounts</h3>
              {accounts.length > 0 ? (
                  accounts.map((account, index) => (
                      <div className="account-card" key={index}>
                          <p className="account-bank">{account.bank_name}</p>
                          <p className="account-number">
                              {account.account_no}
                              &nbsp;<FaCopy size={20} className="copy-icon" onClick={() => copyToClipboard(account.account_no)} />
                          </p>
                      </div>
                  ))
              ) : (
                  <p>Loading available accounts...</p>
              )} 
            </div>
          </div>

          {/* Actions */}
          <div className="actions-container">
            <center>
              {actions.map((action, index) => (
                <a href={action.url} key={index} className="action-item">
                  {action.icon}
                  <span className="action-text">{action.name}</span>
                </a>
              ))}
            </center>
          </div>

          {/* Transaction history */}
          <div className="transaction-history">
            <h3>Transaction history</h3>
            
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th>S/N</th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Amount</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {walletTransactions.length > 0 ? (
                    walletTransactions.map((transaction, index) => (
                      <tr key={index}>
                        <td>{ index + 1}</td>
                        <td>{transaction.transaction_details}</td>
                        <td>{transaction.transaction_type}</td>
                        <td>₦ {parseFloat(transaction.settlement_amount).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                        </td>
                        <td>
                          {transaction.created_at ? (
                            format(new Date(transaction.created_at), "do EEEE, yyyy h:mma")
                          ) : (
                            "Invalid Date"
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr><td colSpan="5"><center>Loading available wallet transactions...</center></td></tr>
                  )}
                  
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        currentLocation.pathname !== '/kyc' && (
          <>
            <div className="kyc-alert">
              <button className="danger-button" onClick={handleKycRedirect}>
                KYC Verification Required - Click to Proceed
              </button>
            </div>
          </>
        )
      )}
    </div>
  );
};

export default MyContent;
