// Dashboard.jsx
import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import Sidebar from '../components/SideBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../assets/Main.css';
import { getAuthUser } from '../utils/auth';


const BuyCableTv = () => {
  const [isOpen, setIsOpen] = useState(true);
  const user = getAuthUser();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it once to set initial state based on screen size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`dashboard-container ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
        
        {/****sidebar****/}
        <button className="toggle-button" onClick={toggleSidebar}>
            {isOpen ? <FaTimes /> : <FaBars />}
        </button>
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        {/***end sidebar*****/}

        <div className="main-content">
            {/*****header****/}
            <Header />
            {/**end header****/}

            {/*****MyContent**/}
            <div className="dashboard">
                <h2>Buy Cable Tv</h2>
                {user.data.user.kyc_status === 1 && (
                  <p>Page Under Construction</p>
                )}
            </div>
            {/*****MyContent**/}

            {/*****Footer**/}
            <Footer />
            {/*****Footer**/}
      </div>
    </div>
  );
};

export default BuyCableTv;
