import React, {useEffect, useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../apiConfig'; // Import the base URL
import { FaUser, FaPhone, FaEnvelope, FaLock, FaEye, FaEyeSlash, FaCode } from 'react-icons/fa';
import '../assets/Login.css'; // Ensure this CSS file is created and referenced correctly
import logo from '../assets/img/rumbundata.png';
import { getAuthUser } from '../utils/auth';
import Swal from 'sweetalert2';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Form state
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    if (getAuthUser()) {
        navigate('/dashboard'); // Redirect to dashboard if the user is logged in
    }
  }, [navigate]);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Validate input on change
    if (errors[name]) {
      validateField(name, value);
    }
  };

  // Validate individual fields
  const validateField = (name, value) => {
    let fieldErrors = { ...errors };

    switch (name) {
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        fieldErrors.email = value
          ? emailRegex.test(value)
            ? ''
            : 'Invalid email address'
          : 'Email is required';
        break;
      case 'password':
        fieldErrors.password = value ? '' : 'Password is required';
        break;
      default:
        break;
    }

    setErrors(fieldErrors);
  };

  // Validate entire form
  const validateForm = () => {
    let formErrors = {};

    if (!formData.email) {
      formErrors.email = 'Email is required';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        formErrors.email = 'Invalid email address';
      }
    }
    if (!formData.password) formErrors.password = 'Password is required';
    
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true); // Start loading
      try {
        const response = await fetch(`${API_BASE_URL}user/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        if (response.ok) {
          const user = await response.json(); // Get user data from response
          localStorage.setItem('authUser', JSON.stringify(user));
          navigate(`/dashboard`); // Redirect to Dashboard
        } else {
            // Display SweetAlert for failed login
            Swal.fire({
                icon: 'error',
                title: 'Login Failed',
                text: 'Please check your email and password and try again.',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Try Again'
            });
        }
      } catch (error) {
        // Display SweetAlert for network or server errors
        Swal.fire({
            icon: 'error',
            title: 'An Error Occurred',
            text: 'Please try again later.',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
        });
      } finally {
        setLoading(false); // Stop loading
      }
    }
  };

 
  return (
    <>
      <div className="login-container">
        <div className="login-content">
          <div className="login-image"></div>
          <div className="login-form">
            <center><img src={logo} alt="Rumbun Data Logo" className="login-logo" /></center>
            <h4>Login</h4>
            <div>
              <div className="input-group">
                <FaEnvelope className="input-icon" />
                <input
                    type="email"
                    name="email"
                    required
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={() => validateField('email', formData.email)}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>
              <div className="input-group">
                <FaLock className="input-icon" />
                <input 
                    type={showPassword ? "text" : "password"} 
                    placeholder="Password" 
                    required 
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    onBlur={() => validateField('password', formData.password)}
                />
                <button type="button" className="eye-button" onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
                {errors.password && <span className="error">{errors.password}</span>}
              </div>
            
              <button 
                className={`login-button ${loading ? 'disabled' : ''}`}
                onClick={handleSubmit}
                >
                {loading ? (
                    <>
                    <div className="spinner"></div>
                    <span>login...</span>
                    </>
                ) : (
                    'Login'
                )}
                </button>
            </div>
            <center>
              <p>Don't have an account? <a href="/signup" className="signup-link">Sign Up</a><br/></p>
            </center>
          </div>
        </div>
      </div>
      <footer>
        <p className='footer-item'>
          Powered by AITS HUB - Support Line: 09030848790, 08136908764
        </p>
      </footer>
    </>
  );
};

export default Login;
