import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/Header.css';
import { FaUserCircle, FaSignOutAlt, FaChevronDown } from 'react-icons/fa';
import userAvatar from '../assets/img/user-avatar.png';
import Swal from 'sweetalert2';
import { API_BASE_URL } from '../apiConfig';
import { getAuthUser } from '../utils/auth';

function Header() {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const navigate = useNavigate();
  const [authUser, setAuthUser] = useState(null);

  const handleLogout = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}user/logout`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authUser.token}`,
        },
      });

      if (response.ok) {
        localStorage.removeItem('authUser');
        navigate('/login');
        Swal.fire({
          icon: 'success',
          title: 'Logout',
          text: 'You are successfully logged out',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'OK',
        });
      } else {
        alert('Logout failed. Please try again.');
      }
    } catch (error) {
      console.error('Error logging out:', error);
      alert('An error occurred during logout. Please try again.');
    }
  };

  useEffect(() => {
    const user = getAuthUser();
    if (user) {
      setAuthUser(user.data);
    } else {
      navigate('/login');
    }
  }, [navigate]);

  if (!authUser) {
    return <div>Loading...</div>;
  }

  const passportUrl = authUser.user.passport || '';

  return (
    <header className="header">
      <div className="header-content">
        <div className="wel">Hi! {authUser.user.username}</div>
        <div className="user-info">
          <div className="profile" onClick={toggleDropdown}>
            {passportUrl ? (
              <img src={passportUrl} alt="User Avatar" className="user-avatar" />
            ) : (
              <img src={userAvatar} alt="User Avatar" className="user-avatar" />
            )}
            <span className="user-name">{authUser.user.phone}</span>
            <FaChevronDown className={`dropdown-arrow ${dropdownOpen ? 'rotate' : ''}`} />
            <div className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
              <ul>
                <li><FaUserCircle /> <a href="#profile">Profile</a></li>
                <li>
                  <FaSignOutAlt />
                  <button className="logout-button" onClick={handleLogout}>Logout</button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
