import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import Sidebar from '../components/SideBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import '../assets/Main.css';
import { getAuthUser } from '../utils/auth';
import { API_BASE_URL } from '../apiConfig'; // Import the base URL
import { format } from 'date-fns';

const TransactionHistory = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [walletTransactions, setWalletTransactions] = useState([]);
  const [authUser, setAuthUser] = useState(getAuthUser());
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(1); // Track total pages

  useEffect(() => {
    const handleResize = () => {
      setIsOpen(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it once on component mount

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch wallet transactions with pagination
  useEffect(() => {
    const fetchWalletTransactions = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`${API_BASE_URL}wallet/all-transactions?page=${currentPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authUser.data.token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setWalletTransactions(data.data.data || []); // Access `data.data`
          setTotalPages(data.data.last_page); // Update total pages
        } else {
          setError('Failed to fetch transactions');
        }
      } catch (error) {
        setError('Error fetching transactions');
      } finally {
        setLoading(false);
      }
    };

    fetchWalletTransactions();
  }, [authUser.data.token, currentPage]); // Re-fetch data when currentPage changes

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };

  return (
    <div className={`dashboard-container ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      {/* Sidebar */}
      <button className="toggle-button" onClick={toggleSidebar}>
        {isOpen ? <FaTimes /> : <FaBars />}
      </button>
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />

      {/* Main content */}
      <div className="main-content">
        <Header />

        <div className="dashboard">
          <h2>Transaction History</h2>

          {authUser && authUser.data.user.kyc_status === 1 ? (
            <div className="transaction-history">
              <div className="table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th>S/N</th>
                      <th>Ref. No.</th>
                      <th>Description</th>
                      <th>Type</th>
                      <th>Amount</th>
                      <th>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="6"><center>Loading transactions...</center></td>
                      </tr>
                    ) : error ? (
                      <tr>
                        <td colSpan="6"><center>{error}</center></td>
                      </tr>
                    ) : walletTransactions.length > 0 ? (
                      walletTransactions.map((transaction, index) => (
                        <tr key={transaction.id}>
                          <td>{(currentPage - 1) * 15 + index + 1}</td> {/* Update index based on currentPage */}
                          <td>{transaction.uuid}</td>
                          <td>{transaction.transaction_details}</td>
                          <td>{transaction.transaction_type}</td>
                          <td>₦ {parseFloat(transaction.settlement_amount).toLocaleString('en-US', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</td>
                          <td>
                            {transaction.created_at ? (
                              format(new Date(transaction.created_at), "do MMMM yyyy, h:mm a")
                            ) : (
                              "Invalid Date"
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6"><center>No transactions found</center></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {/* Pagination Controls */}
              {/* Pagination Controls */}
              <div className="pagination">
                <button
                  className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                >
                  Previous
                </button>
                <span className="pagination-info">
                  Page {currentPage} of {totalPages}
                </span>
                <button
                  className={`pagination-button ${currentPage === totalPages ? 'disabled' : ''}`}
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  Next
                </button>
              </div>

            </div>
          ) : (
            <p>Your KYC status is not verified</p>
          )}
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default TransactionHistory;
